.container {
    display: flex;
    flex-direction: column;
    padding: 5rem 2rem 5rem 2rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 3rem;

    max-width: 1280px;
    margin: 0 auto;

    /* Tablet: height */
    @media (min-width: 641px)  {
        justify-content: start;
        padding-top: 5rem;
    }

    /* Desktop: min-height */
    @media (min-width: 1025px) {
        min-height: 40vh;
    }
}

.button-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1em;
    justify-content: center;
    align-items: center;
}