:root {
  --dust: #F7F3ED;
  --sand: #F2E8DC;
  --soil: #EAD7C2;
  --clay: #E8C6A9;
  --humus: #D7A27F;
  --stone: #C97F55;
  --terracotta: #BE7B54;
  --lava: #A2450E;
  --earth: #75391b;
  --bravery: #132023;
  --courage: #003942;
  --trust: #1B6875;
  --confide: #639797;
  --expectation: #A1C2BC;
  --believe: #CADFD4; 
  --hope: #EDF4F1;
  --warmwhite: #FCFAFA;
  --darkgrey: #3C3C3C;
}


html, body {
  margin: 0;
  font-family: 'Gayathri', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 400;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3C3C3C;

  /* scroll-behavior: smooth; */
}

@font-face {
  font-family: Amelie;
  src: url(assets/Amelie-Regular.woff2);
}