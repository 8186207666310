.Document {
  width: 70vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5vw solid #20676B;
}

@media screen and (min-width: 641px){
  .Document {
    width: 70vw;
  }

  .Example__container__document .react-pdf__Page {
    max-width: 100%;
  }
}

@media screen and (min-width: 1024px){
  .Document {
    width: 50vw;
  }
}

@media screen and (min-width: 1100px){
  .Document {
    width: 30vw;
  }
}

.Page {
  width: inherit;
}

.Example__container__document .react-pdf__Page {
  /* max-width: 50%; */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.Example__container__document .react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}

.react-pdf__Page__textContent textLayer {
  max-width: 1vw;
  height: auto !important;
}

.Example__container__document div {
  max-width: 100%;
  /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.5); */
}
